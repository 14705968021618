import {
  DatePicker as AntdDatePicker,
  Form,
  Input as AntdInput,
  InputNumber as AntdInputNumber,
  Select as AntdSelect,
} from "antd"
import { connect, getIn } from "formik"
import moment from "moment"
import React from "react"

export const FormItem = connect(({ formik, name, ...props }) => {
  const error = getIn(formik.errors, name)
  const touched = getIn(formik.touched, name)
  const err = touched && error
  return <Form.Item help={err} validateStatus={err && "error"} {...props} />
})

export const DatePicker = connect(({ formik, name, ...props }) => {
  const value = getIn(formik.values, name)
  return (
    <AntdDatePicker
      name={name}
      value={value ? moment(value) : undefined}
      onChange={value => {
        formik.setFieldValue(name, value ? value.toISOString() : "")
        formik.setFieldTouched(name)
      }}
      {...props}
    />
  )
})

const withField = Component =>
  connect(({ formik, name, ...props }) => (
    <Component
      name={name}
      value={getIn(formik.values, name)}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      {...props}
    />
  ))
export const Input = withField(AntdInput)
Input.Password = withField(AntdInput.Password)
Input.TextArea = withField(AntdInput.TextArea)

export const InputNumber = connect(({ formik, name, ...props }) => (
  <AntdInputNumber
    name={name}
    value={getIn(formik.values, name)}
    onBlur={formik.handleBlur}
    onChange={value => formik.setFieldValue(name, value || 0)}
    {...props}
  />
))

export const Select = connect(({ formik, name, ...props }) => (
  <AntdSelect
    name={name}
    value={getIn(formik.values, name)}
    onBlur={() => formik.setFieldTouched(name)}
    onChange={value => formik.setFieldValue(name, value)}
    {...props}
  />
))
Select.Option = AntdSelect.Option
