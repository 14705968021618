import {
  Breadcrumb,
  Button,
  Card,
  Form,
  Icon,
  message,
  Spin,
  Table,
} from "antd"
import { gql } from "apollo-boost"
import { FieldArray, Formik } from "formik"
import { Link } from "gatsby"
import moment from "moment"
import React from "react"
import { Mutation, Query } from "react-apollo"
import * as yup from "yup"
import {
  DatePicker,
  FormItem,
  Input,
  InputNumber,
  Select,
} from "../../components/antd-formik"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const { Option } = Select
const { Column } = Table

const formItemLayoutWithLabel = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
}

const formItemLayoutWithoutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
}

const CREATE_STOCK_OUT = gql`
  mutation createStockOut($objects: [mbt_stock_outs_insert_input!]!) {
    insert_mbt_stock_outs(objects: $objects) {
      returning {
        customer {
          name
        }
        date
        no_doc
      }
    }
  }
`

const LIST_AUTO_COMPLETE = gql`
  query listAutoComplete {
    mbt_customers(order_by: { name: asc }) {
      id
      name
    }
    mbt_items(order_by: { name: asc }, where: { current_stock: { _gt: 0 } }) {
      id
      name
    }
    mbt_marketplaces(order_by: { name: asc }) {
      id
      name
    }
  }
`

const initialDetail = { item_id: "", quantity: 0 }
const stockOutSchema = yup.object({
  customer_id: yup
    .string()
    .label("Customer")
    .required(),
  marketplace_id: yup
    .string()
    .label("Marketplace")
    .required(),
  date: yup
    .date()
    .label("Date")
    .required(),
  no_doc: yup
    .string()
    .label("No doc")
    .required(),
  details: yup.object({
    data: yup.array(
      yup
        .object({
          item_id: yup
            .string()
            .label("Item")
            .required(),
          quantity: yup
            .number()
            .typeError("Quantity is an invalid number")
            .label("Quantity")
            .min(1)
            .required(),
        })
        .required()
    ),
  }),
})

const StockOutForm = () => (
  <Mutation mutation={CREATE_STOCK_OUT}>
    {(createStockOut, { loading }) => (
      <Query fetchPolicy="cache-and-network" query={LIST_AUTO_COMPLETE}>
        {({ data, loading: queryLoading }) => (
          <Formik
            initialValues={{
              date: moment().toISOString(),
              details: { data: [initialDetail] },
            }}
            validationSchema={stockOutSchema}
            onSubmit={async (objects, { resetForm }) => {
              try {
                await createStockOut({ variables: { objects: [objects] } })
                resetForm({
                  date: moment().toISOString(),
                  details: { data: [initialDetail] },
                })
                message.success("Successfully added new stock out")
              } catch (err) {
                message.error("Failed to add new stock out")
              }
            }}
          >
            {({ dirty, handleSubmit, handleReset, values }) => (
              <Form
                {...formItemLayoutWithLabel}
                onReset={handleReset}
                onSubmit={handleSubmit}
              >
                <FormItem name="customer_id" label="Customer">
                  <Select
                    disabled={loading}
                    name="customer_id"
                    notFoundContent={
                      queryLoading ? <Spin size="small" /> : null
                    }
                    optionFilterProp="children"
                    showSearch
                  >
                    {(data.mbt_customers || []).map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem name="marketplace_id" label="Marketplace">
                  <Select
                    disabled={loading}
                    name="marketplace_id"
                    notFoundContent={
                      queryLoading ? <Spin size="small" /> : null
                    }
                    optionFilterProp="children"
                    showSearch
                  >
                    {(data.mbt_marketplaces || []).map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem name="date" label="Date">
                  <DatePicker name="date" disabled={loading} format="L" />
                </FormItem>
                <FormItem name="no_doc" label="No doc">
                  <Input disabled={loading} name="no_doc" />
                </FormItem>
                <Form.Item {...formItemLayoutWithoutLabel}>
                  <FieldArray
                    name="details.data"
                    render={({ push, remove }) => (
                      <Table
                        bordered
                        dataSource={values.details.data}
                        pagination={false}
                        rowKey={(record, index) => index}
                        size="small"
                        title={() => (
                          <Button
                            type="primary"
                            icon="plus"
                            disabled={loading}
                            onClick={() => push(initialDetail)}
                          >
                            Add
                          </Button>
                        )}
                      >
                        <Column
                          title="Item"
                          key="item_id"
                          width="60%"
                          render={(text, record, index) => (
                            <FormItem name={`details.data.${index}.item_id`}>
                              <Select
                                disabled={loading}
                                name={`details.data.${index}.item_id`}
                                notFoundContent={
                                  queryLoading ? <Spin size="small" /> : null
                                }
                                optionFilterProp="children"
                                showSearch
                              >
                                {(data.mbt_items || []).map(({ id, name }) => (
                                  <Option key={id} value={id}>
                                    {name}
                                  </Option>
                                ))}
                              </Select>
                            </FormItem>
                          )}
                        />
                        <Column
                          title="Quantity"
                          key="quantity"
                          width="40%"
                          render={(text, record, index) => (
                            <FormItem name={`details.data.${index}.quantity`}>
                              <InputNumber
                                disabled={loading}
                                name={`details.data.${index}.quantity`}
                                style={{ width: "100%" }}
                                min={0}
                              />
                            </FormItem>
                          )}
                        />
                        <Column
                          title="Action"
                          key="action"
                          render={(text, record, index) => (
                            <Button
                              type="danger"
                              icon="close"
                              disabled={
                                loading || values.details.data.length === 1
                              }
                              onClick={() => remove(index)}
                            />
                          )}
                        />
                      </Table>
                    )}
                  />
                </Form.Item>
                <Form.Item {...formItemLayoutWithoutLabel}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!dirty}
                    loading={loading}
                  >
                    Save
                  </Button>{" "}
                  <Button htmlType="reset" disabled={loading || !dirty}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Formik>
        )}
      </Query>
    )}
  </Mutation>
)

export default () => (
  <Layout>
    <SEO title="Stock Out" />
    <Breadcrumb style={{ margin: "16px 0" }}>
      <Breadcrumb.Item>
        <Link to="/">
          <Icon type="home" />
        </Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Link to="/stock-out/">Stock Out</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>New</Breadcrumb.Item>
    </Breadcrumb>
    <Card>
      <StockOutForm />
    </Card>
  </Layout>
)
